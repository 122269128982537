var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"my-groups","fluid":"","tag":"section"}},[_c('v-overlay',{attrs:{"value":_vm.isOverlayVisible}},[_c('v-progress-circular',{staticClass:"spinner",attrs:{"size":70,"width":7,"color":"amber","indeterminate":""}})],1),_c('base-v-component',{attrs:{"heading":"Senders","sub-heading":"Sender List"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.senders,"sort-by":"senderName"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"senderActions"},[_c('v-btn',{attrs:{"color":"primary","outlined":"","x-small":""},on:{"click":function($event){return _vm.editSender(item)}}},[_vm._v(" Edit ")])],1)]}},{key:"item.sendNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._formatSenderNumberBasedOnSenderType(item))+" ")]}},{key:"no-data",fn:function(){return [_c('p',{staticClass:"mb-4 mt-4"},[_vm._v(" No groups to display ")]),_c('v-btn',{staticClass:"mb-4",attrs:{"color":"primary","outlined":""},on:{"click":_vm.initialize}},[_vm._v(" Refresh ")])]},proxy:true}])}),_c('sender-add-edit-dialog',{attrs:{"selected-sender":_vm.selectedSenderToEdit,"is-edit-mode":_vm.isSenderEditMode,"is-visible":_vm.isSenderDialogVisible,"sender-response-actions":_vm.senderResponseActions},on:{"on-submit":_vm.onSenderAddEditDialogSubmit,"on-close":_vm.hideSenderAddEditDialog,"on-success":_vm.onSenderAddEditDialogSubmitSuccess,"on-error":_vm.onSenderAddEditDialogSubmitError}}),_c('div',{staticClass:"titleWrapper"},[_c('base-v-component',{attrs:{"heading":"Services","sub-heading":"Service List"}})],1),[_c('v-toolbar',{staticClass:"actionbar",attrs:{"flat":"","color":"transparent"}},[(_vm.phoneNumbers && _vm.phoneNumbers.length)?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.addMessagingService}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Add Messaging Service ")],1):_vm._e(),_c('messaging-service-add-edit-dialog',{attrs:{"selected-messaging-service":_vm.selectedMessagingServiceToEdit,"is-edit-mode":_vm.isMessagingServiceEditMode,"is-visible":_vm.isMessagingServiceDialogVisible},on:{"on-submit":_vm.onMessagingServiceAddEditDialogSubmit,"show-loader":_vm.showLoader,"hide-loader":_vm.hideLoader,"on-close":_vm.hideMessagingServiceAddEditDialog,"on-success":_vm.onMessagingServiceAddEditDialogSubmitSuccess,"on-error":_vm.onMessagingServiceAddEditDialogSubmitError}})],1)],_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.servicesHeaders,"items":_vm.services,"sort-by":"senderName"},scopedSlots:_vm._u([{key:"item.deploy",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"value":item.isDeployed},on:{"click":function($event){return _vm.editMessagingService(item)}}})]}},{key:"no-data",fn:function(){return [_c('p',{staticClass:"mb-4 mt-4"},[_vm._v(" No services to display ")]),_c('v-btn',{staticClass:"mb-4",attrs:{"color":"primary","outlined":""},on:{"click":_vm.initialize}},[_vm._v(" Refresh ")])]},proxy:true}])}),_c('div',{staticClass:"titleWrapper"},[_c('base-v-component',{attrs:{"heading":"Phone Numbers","sub-heading":"Phone Numbers List"}})],1),[_c('v-toolbar',{staticClass:"actionbar",attrs:{"flat":"","color":"transparent"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.showTwilioProvisionDialog}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Add Twilio Number ")],1),_c('add-twilio-provision-number-dialog',{attrs:{"is-visible":_vm.isAddTwilioProvisionNumberDialogVisible},on:{"on-submit":_vm.onTwilioProvisionSubmit,"on-close":_vm.hideTwilioProvisionDialog,"on-success":_vm.onTwilioProvisionSuccess,"on-error":_vm.onTwilioProvisionError,"on-spinner-hide":_vm.onSpinnerHide}}),_c('phone-number-add-edit-dialog',{attrs:{"selected-phone-number":_vm.selectedPhoneNumberToEdit,"is-edit-mode":_vm.isPhoneEditMode,"is-visible":_vm.isPhoneDialogVisible},on:{"on-submit":_vm.onPhoneAddEditDialogSubmit,"on-close":_vm.hidePhoneAddEditDialog,"on-success":_vm.onPhoneAddEditDialogSubmitSuccess,"on-error":_vm.onPhoneAddEditDialogSubmitError}})],1)],_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.phoneNumberHeaders,"items":_vm.phoneNumbers,"sort-by":"senderName"},scopedSlots:_vm._u([{key:"item.deploy",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"value":item.isDeployed},on:{"click":function($event){return _vm.editPhoneNumber(item)}}})]}},{key:"no-data",fn:function(){return [_c('p',{staticClass:"mb-4 mt-4"},[_vm._v(" No phone Numbers to display ")]),_c('v-btn',{staticClass:"mb-4",attrs:{"color":"primary","outlined":""},on:{"click":_vm.initialize}},[_vm._v(" Refresh ")])]},proxy:true}])}),_c('share-sender-dialog',{attrs:{"selected-sender":_vm.selectedSenderToShare,"is-visible":_vm.isSenderShareDialogVisible,"users":_vm.allUsers},on:{"on-submit":_vm.onSenderShareSubmit,"on-close":_vm.hideSenderShareDialog,"on-success":_vm.onSenderShareSuccess,"on-error":_vm.onSenderShareError}}),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.notification)+" ")]),_c('div',{staticClass:"py-3"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }