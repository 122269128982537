<template>
  <v-container
    id="my-groups"
    fluid
    tag="section"
  >
    <v-overlay :value="isOverlayVisible">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
        class="spinner"
      />
    </v-overlay>
    <base-v-component
      heading="Senders"
      sub-heading="Sender List"
    />

    <v-data-table
      :headers="headers"
      :items="senders"
      sort-by="senderName"
      class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
        <div class="senderActions">
          <v-btn
            color="primary"
            outlined
            x-small
            @click="editSender(item)"
          >
            Edit
          </v-btn>
        </div>
      </template>
      <template v-slot:item.sendNumber="{ item }">
        {{ _formatSenderNumberBasedOnSenderType(item) }}
      </template>
      <template v-slot:no-data>
        <p class="mb-4 mt-4">
          No groups to display
        </p>
        <v-btn
          color="primary"
          outlined
          class="mb-4"
          @click="initialize"
        >
          Refresh
        </v-btn>
      </template>
    </v-data-table>
    <sender-add-edit-dialog
      :selected-sender="selectedSenderToEdit"
      :is-edit-mode="isSenderEditMode"
      :is-visible="isSenderDialogVisible"
      :sender-response-actions="senderResponseActions"
      @on-submit="onSenderAddEditDialogSubmit"
      @on-close="hideSenderAddEditDialog"
      @on-success="onSenderAddEditDialogSubmitSuccess"
      @on-error="onSenderAddEditDialogSubmitError"
    />
    <div class="titleWrapper">
      <base-v-component
        heading="Services"
        sub-heading="Service List"
      />
    </div>
    <template>
      <v-toolbar
        flat
        color="transparent"
        class="actionbar"
      >
        <v-btn
          v-if="phoneNumbers && phoneNumbers.length"
          color="primary"
          dark
          class="mb-2"
          @click="addMessagingService"
        >
          <v-icon
            small
            class="mr-2"
          >
            mdi-plus
          </v-icon>
          Add Messaging Service
        </v-btn>
        <messaging-service-add-edit-dialog
          :selected-messaging-service="selectedMessagingServiceToEdit"
          :is-edit-mode="isMessagingServiceEditMode"
          :is-visible="isMessagingServiceDialogVisible"
          @on-submit="onMessagingServiceAddEditDialogSubmit"
          @show-loader="showLoader"
          @hide-loader="hideLoader"
          @on-close="hideMessagingServiceAddEditDialog"
          @on-success="onMessagingServiceAddEditDialogSubmitSuccess"
          @on-error="onMessagingServiceAddEditDialogSubmitError"
        />
      </v-toolbar>
    </template>

    <v-data-table
      :headers="servicesHeaders"
      :items="services"
      sort-by="senderName"
      class="elevation-1"
    >
      <template v-slot:item.deploy="{item}">
        <v-simple-checkbox
          :value="item.isDeployed"
          @click="editMessagingService(item)"
        />
      </template>
      <template v-slot:no-data>
        <p class="mb-4 mt-4">
          No services to display
        </p>
        <v-btn
          color="primary"
          outlined
          class="mb-4"
          @click="initialize"
        >
          Refresh
        </v-btn>
      </template>
    </v-data-table>

    <div class="titleWrapper">
      <base-v-component
        heading="Phone Numbers"
        sub-heading="Phone Numbers List"
      />
    </div>

    <template>
      <v-toolbar
        flat
        color="transparent"
        class="actionbar"
      >
        <v-btn
          color="primary"
          dark
          class="mb-2"
          @click="showTwilioProvisionDialog"
        >
          <v-icon
            small
            class="mr-2"
          >
            mdi-plus
          </v-icon>
          Add Twilio Number
        </v-btn>
        <add-twilio-provision-number-dialog
          :is-visible="isAddTwilioProvisionNumberDialogVisible"
          @on-submit="onTwilioProvisionSubmit"
          @on-close="hideTwilioProvisionDialog"
          @on-success="onTwilioProvisionSuccess"
          @on-error="onTwilioProvisionError"
          @on-spinner-hide="onSpinnerHide"
        />
        <phone-number-add-edit-dialog
          :selected-phone-number="selectedPhoneNumberToEdit"
          :is-edit-mode="isPhoneEditMode"
          :is-visible="isPhoneDialogVisible"
          @on-submit="onPhoneAddEditDialogSubmit"
          @on-close="hidePhoneAddEditDialog"
          @on-success="onPhoneAddEditDialogSubmitSuccess"
          @on-error="onPhoneAddEditDialogSubmitError"
        />
      </v-toolbar>
    </template>

    <v-data-table
      :headers="phoneNumberHeaders"
      :items="phoneNumbers"
      sort-by="senderName"
      class="elevation-1"
    >
      <template v-slot:item.deploy="{item}">
        <v-simple-checkbox
          :value="item.isDeployed"
          @click="editPhoneNumber(item)"
        />
      </template>
      <template v-slot:no-data>
        <p class="mb-4 mt-4">
          No phone Numbers to display
        </p>
        <v-btn
          color="primary"
          outlined
          class="mb-4"
          @click="initialize"
        >
          Refresh
        </v-btn>
      </template>
    </v-data-table>
    <share-sender-dialog
      :selected-sender="selectedSenderToShare"
      :is-visible="isSenderShareDialogVisible"
      :users="allUsers"
      @on-submit="onSenderShareSubmit"
      @on-close="hideSenderShareDialog"
      @on-success="onSenderShareSuccess"
      @on-error="onSenderShareError"
    />
    <v-snackbar
      v-model="snackbar"
    >
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <div class="py-3" />
  </v-container>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'

  export default {
    components: {
      PhoneNumberAddEditDialog: () => import('../components/PhoneNumberAddEditDialog'),
      MessagingServiceAddEditDialog: () => import('../components/MessagingServiceAddEditDialog'),
      SenderAddEditDialog: () => import('../components/SenderAddEditDialog'),
      ShareSenderDialog: () => import('../components/ShareSenderDialog'),
      AddTwilioProvisionNumberDialog: () => import('../components/AddTwilioProvisionNumberDialog'),
    },
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      headers: [
        { text: 'Sender Name', value: 'senderName' },
        { text: 'Sender Type', value: 'senderType' },
        { text: 'Sender Number', value: 'sendNumber' },
        { text: 'Invite Code', value: 'inviteCode' },
        { text: 'Signiture', value: 'signiture' },
        { text: 'Welcome message', value: 'welcomeMessage' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      servicesHeaders: [
        { text: 'Service Name', value: 'MSName' },
        { text: 'Deploy', value: 'deploy', sortable: false },
      ],
      phoneNumberHeaders: [
        { text: 'Phone Number', value: 'PhoneNumber' },
        { text: 'Name', value: 'Name' },
        { text: 'Deploy', value: 'deploy', sortable: false },
      ],
      snackbar: false,
      notification: 'Unknown',
      senders: [],
      services: [],
      phoneNumbers: [],
      isOverlayVisible: true,
      selectedPhoneNumberToEdit: null,
      isPhoneEditMode: false,
      isPhoneDialogVisible: false,
      selectedMessagingServiceToEdit: null,
      isMessagingServiceEditMode: false,
      isMessagingServiceDialogVisible: false,
      selectedSenderToEdit: null,
      isSenderEditMode: false,
      isSenderDialogVisible: false,
      selectedSenderToShare: null,
      isSenderShareDialogVisible: false,
      isAddTwilioProvisionNumberDialogVisible: false,
      allUsers: [],
      senderResponseActions: [],
    }),

    computed: {},

    watch: {},

    async created () {
      this.usremail = localStorage.getItem('username')
      await this.sleepTime(1000)
      await this.initialize()
    },

    methods: {
      // eslint-disable-next-line no-new
      sleepTime (n) { new Promise((resolve, reject) => setTimeout(() => resolve(), n)) },

      async initialize () {
        try {
          this.isOverlayVisible = true

          await this.fetchSenders()
          await this.fetchPhoneNumbers()
          await this.fetchMessagingServices()
          await this.fetchUsers()
          this.isOverlayVisible = false
        } catch (error) {
          this.isOverlayVisible = false
        }
      },

      formatNumber (number) {
        let maskedNumber = ''
        maskedNumber = number.replace('+1', '').replace(/[^0-9,]+/g, '')
        maskedNumber = `+1 (${maskedNumber.slice(0, 3)}) ${maskedNumber.slice(3, 6)}-${maskedNumber.slice(6, 10)}`
        return maskedNumber
      },

      editPhoneNumber (item) {
        if (!item.isDeployed) {
          this.isPhoneEditMode = true
          this.selectedPhoneNumberToEdit = item
          this.isPhoneDialogVisible = true
        } else {
          this.notification = 'Number already deployed!'
          this.snackbar = true
        }
      },

      addPhoneNumber () {
        this.isPhoneEditMode = false
        this.isPhoneDialogVisible = true
      },

      onPhoneAddEditDialogSubmit () {
        this.isOverlayVisible = true
      },

      async onPhoneAddEditDialogSubmitSuccess () {
        this.isPhoneDialogVisible = false
        await this.fetchPhoneNumbers()
        this.isOverlayVisible = false
      },

      onPhoneAddEditDialogSubmitError () {
        this.isOverlayVisible = false
      },

      hidePhoneAddEditDialog () {
        this.isPhoneDialogVisible = false
      },

      async fetchPhoneNumbers () {
        try {
          const phoneNumberResponse = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}smssendphonenumbers?code=${this.apiCode}`)
          this.phoneNumbers = phoneNumberResponse.data
        } catch (error) {
        }
      },

      editMessagingService (item) {
        if (!item.isDeployed) {
          this.isMessagingServiceEditMode = true
          this.selectedMessagingServiceToEdit = item
          this.isMessagingServiceDialogVisible = true
        } else {
          this.notification = 'Service already deployed!'
          this.snackbar = true
        }
      },

      addMessagingService () {
        this.isMessagingServiceEditMode = false
        this.isMessagingServiceDialogVisible = true
      },

      onMessagingServiceAddEditDialogSubmit () {
        this.isOverlayVisible = true
      },

      async onMessagingServiceAddEditDialogSubmitSuccess () {
        this.isMessagingServiceDialogVisible = false
        await this.fetchMessagingServices()
        this.isOverlayVisible = false
      },

      onMessagingServiceAddEditDialogSubmitError () {
        this.isOverlayVisible = false
      },

      hideMessagingServiceAddEditDialog () {
        this.isMessagingServiceDialogVisible = false
      },

      async fetchMessagingServices () {
        try {
          const servicesResponse = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}messagingservices?code=${this.apiCode}`)
          this.services = servicesResponse.data
        } catch (error) {

        }
      },

      editSender (item) {
        this.isSenderEditMode = true
        this.selectedSenderToEdit = item
        this.isSenderDialogVisible = true
      },

      onSenderAddEditDialogSubmit () {
        this.isOverlayVisible = true
      },

      async onSenderAddEditDialogSubmitSuccess () {
        this.isSenderDialogVisible = false
        await this.fetchSenders()
        this.isOverlayVisible = false
      },

      onSenderAddEditDialogSubmitError () {
        this.isOverlayVisible = false
      },

      hideSenderAddEditDialog () {
        this.isSenderDialogVisible = false
      },

      _formatSenderNumberBasedOnSenderType (item) {
        if (item.senderType === 'MessagingService') {
          return item.sendNumber
        } else {
          return this.formatNumber(item.sendNumber)
        }
      },

      async fetchSenders () {
        try {
          this.senders = []
          const sendersResponse = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}senders/?code=${this.apiCode}`)
          sendersResponse.data.forEach((sender, senderIndex) => {
            this.senders.push({
              ...sender,
              rowId: senderIndex,
            })
          })
        } catch (error) {
        }
      },

      showSenderShareDialog (senderId) {
        this.selectedSenderToShare = senderId
        this.isSenderShareDialogVisible = true
      },

      onSenderShareSubmit () {
        this.isOverlayVisible = true
      },

      hideSenderShareDialog () {
        this.selectedSenderToShare = null
        this.isSenderShareDialogVisible = false
      },

      onSenderShareSuccess () {
        this.isOverlayVisible = false
        this.hideSenderShareDialog()
      },

      onSenderShareError () {
        this.isOverlayVisible = false
      },

      async fetchUsers () {
        try {
          const usersResponse = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}users?code=${this.apiCode}`)
          this.allUsers = usersResponse.data
        } catch (error) {}
      },

      showTwilioProvisionDialog () {
        this.isAddTwilioProvisionNumberDialogVisible = true
      },

      onTwilioProvisionSubmit () {
        this.isOverlayVisible = true
      },

      hideTwilioProvisionDialog () {
        this.isAddTwilioProvisionNumberDialogVisible = false
      },

      onTwilioProvisionSuccess () {
        this.isOverlayVisible = false
        this.hideTwilioProvisionDialog()
        this.notification = 'Phone Added Successfully'
        this.snackbar = true
      },

      onTwilioProvisionError () {
        this.isOverlayVisible = false
      },

      onSpinnerHide () {
        this.isOverlayVisible = false
      },

      showLoader () {
        this.isOverlayVisible = true
      },

      hideLoader () {
        this.isOverlayVisible = false
      },
    },
  }

</script>

<style scoped>
  .v-overlay.v-overlay--active.theme--dark {
    z-index: 300!important;
  }
  .spinner {
    margin-left: 17rem;
  }
  @media only screen and (max-width: 968px) {
    .spinner {
      margin-left: 0;
    }
  }
  .elevation-1 {
    margin-top: 3rem!important;
  }

  .titleWrapper {
    margin-top: 4rem;
    margin-bottom: -1rem;
  }

  .actionbar {
    margin-bottom: -25px;
    display: flex!important;
    justify-content: flex-end!important;
  }

  .senderActions {
    display: flex;
    width: 100px;
    justify-content: space-between;
  }
</style>
